<template>
  <b-card no-body>
    <b-card-body class="text-right">
      <b-button
        variant="primary"
        :to="$route.path + '/add'"
      >
        <FeatherIcon icon="PlusIcon" /> Oluştur
      </b-button>
    </b-card-body>
    <b-table-simple
      striped
    >
      <b-thead>
        <b-tr>
          <b-th colspan="2">
            BAŞLIK
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="menuItem in dataList">
          <b-tr :key="menuItem.id">
            <b-td>{{ menuItem.title }}</b-td>
            <b-td>
              <div class="text-right">
                <list-buttons
                  :edit-action="$route.path + '/edit/' + menuItem.id"
                  :delete-action="removeData"
                  :data-id="menuItem.id"
                />
              </div>
            </b-td>
          </b-tr>
          <template v-if="menuItem.subItems.length > 0">
            <b-tr
              v-for="subItems in menuItem.subItems"
              :key="'subItem_' + subItems.id"
              class="bg-light-warning"
            >
              <b-td><FeatherIcon icon="ArrowRightCircleIcon" /> {{ subItems.title }}</b-td>
              <b-td>
                <div class="text-right">
                  <list-buttons
                    :edit-action="$route.path + '/edit/' + subItems.id"
                    :delete-action="removeData"
                    :data-id="subItems.id"
                  />
                </div>
              </b-td>
            </b-tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BButton, BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListButtons.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    ListButtons,

  },
  computed: {
    dataList() {
      return this.$store.getters['menus/dataList']
    },
    saveData() {
      return this.$store.getters['menus/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('menus/getDataList')
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('menus/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
